// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as LangProvider from "../shared/LangProvider.re.mjs";
import * as Core from "@linaria/core";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import Btn_login_base2xPng from "./btn_login_base@2x.png";

import { t } from '@lingui/macro'
;

var btn_login_base = Btn_login_base2xPng;

function LoginLink(props) {
  var match = ReactRouterDom.useLocation();
  var baseClass = "";
  return JsxRuntime.jsx(LangProvider.Router.LinkWithOpts.make, {
              to: {
                pathname: "/oauth-login",
                search: ReactRouterDom.createSearchParams({
                        return: match.pathname
                      }).toString()
              },
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("img", {
                          className: "w-32",
                          alt: t`login with Line`,
                          src: btn_login_base
                        }),
                    className: "inline-block align-middle",
                    alt: t`login with Line`
                  }),
              className: Core__Option.getOr(Core__Option.map(props.className, (function (c) {
                          return Core.cx(c, baseClass);
                        })), baseClass)
            });
}

var make = LoginLink;

export {
  btn_login_base ,
  make ,
}
/*  Not a pure module */
